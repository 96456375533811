import React from 'react';
import Image from 'next/image';
import Stack from '@mui/material/Stack';
import { Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Spacer from './spacer';
import theme from '../theme';

interface Props {
    name: string,
    image: string,
    longText: string,
    shortText: string,
    headline?: string,
    customerName: string,
    backgroundColor: string
}

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

const useStyles = makeStyles(theme => ({
    imageAndText: {
        textAlign: 'center',
    },
    shortText: {
        margin: 'auto',
        width: '200px',
    },
    longText: {
        textAlign: 'center',
        margin: 'auto',
        width: '300px',
        [theme.breakpoints.up('md')]: {
            width: '80%',
            textAlign: 'left',
            margin: 'inherit',
        },
    },
    customerName: {
        paddingTop: theme.spacing(1),
    },
    avatarAndTextDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    avatarAndTextMobile: {
        display: 'block',
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    avatarStack: {
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'start',
        },
    },
}));

const ImageComponent = ({ imageUrl, }) => (
    <Image src={base + imageUrl} alt="TheCode Cosmetics Set" width={250} height={250}/>
);

const AvatarAndText = ({ classes, customerName, longText, }) => (
    <React.Fragment>
        <Stack direction="row" spacing={2} className={classes.avatarStack}>
            <Avatar>{customerName[0]}</Avatar>
            <Typography component="p" variant="h5" className={classes.customerName} >{customerName}</Typography>
        </Stack>
        <Spacer spaceDesktop={2} spaceTablet={2} spaceMobile={2} />
        <Typography className={classes.longText}>
            {longText}
        </Typography>
    </React.Fragment>
);

export default function CustomerReview (props: Props) {
    const classes = useStyles(theme);
    const { name, customerName, image, shortText, longText, headline, backgroundColor = 'black', } = props;

    const useRootStyles = makeStyles(theme => ({
        root: {
            width: '100vw',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            backgroundColor: backgroundColor,
            position: 'relative',
            left: backgroundColor === 'black' ? 'inherit' : '50%',
            color: backgroundColor === 'black' ? 'inherit' : 'black',
            marginLeft: backgroundColor === 'black' ? 'inherit' : '-50vw',
            border: backgroundColor === 'black' ? '1px solid white' : 'none',
        },
        headline: {
            textAlign: 'center',
            paddingBottom: theme.spacing(3),
        },
    }));

    const rootClasses = useRootStyles(theme);

    return (
        <div className={rootClasses.root} id={(name && encodeURI(name))}>
            <Grid container item xs={12}>
                {headline && (
                    <Grid item xs={12} className={rootClasses.headline}>
                        <Typography variant="h4">
                            {headline}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.avatarAndTextMobile}>
                    <AvatarAndText classes={classes} customerName={customerName} longText={longText} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.imageAndText}>
                        <ImageComponent imageUrl={image} />
                        <Spacer spaceDesktop={2} spaceTablet={2} spaceMobile={2} />
                        <Typography variant="body2" className={classes.shortText}>
                            {shortText}
                        </Typography>
                    </div>
                </Grid>
                <Grid container item xs={12} md={6} direction="column" justifyContent="center" className={classes.avatarAndTextDesktop}>
                    <AvatarAndText classes={classes} customerName={customerName} longText={longText} />
                </Grid>
            </Grid>
        </div>
    );
}
