import React from 'react';

export default function Headline (props) {
    React.useEffect(() => {
        if (window.fbq) {
            window.fbq('track', props.eventName);
        }
    }, []);

    return <span />;
}
