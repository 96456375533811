import React from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, FormControlLabel, Button, Checkbox, Radio, FormGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import theme from '../../theme';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';

const useStyles = makeStyles(() => ({
    button: {
        color: 'white',
        textTransform: 'none',
        backgroundColor: 'black',
        marginRight: theme.spacing(1),
        borderRadius: 0,
        minWidth: '90%',
        [theme.breakpoints.up('lg')]: {
            minWidth: '121px',
        },
        '&:hover': {
            backgroundColor: 'black',
        },
    },
}));

const isChecked = (values, val) => values.map(v => v.toLowerCase()).includes(val.toLowerCase());

export default function ProductListing ({ name, filterProperties, callBack, selectedFilters, useCheckbox = true, }) {
    const router = useRouter();
    const classes = useStyles(theme);
    const translations = getTranslatedContent('translations', router.locale);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [values, setValues] = React.useState(selectedFilters || []);

    const openFilterMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeFilterMenu = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        const element = event.target.name.toLowerCase();
        const isChecked = values.includes(element);

        if (!useCheckbox) {
            setValues([element]);
        } else if (isChecked) {
            const newValues = [...values].filter(v => v !== element);
            setValues(newValues);
        } else {
            const newValues = [...values, element];
            setValues(newValues);
        }
    };

    React.useEffect(() => {
        callBack({ [name]: values, });
    }, [values]);

    React.useEffect(() => {
        // add a default value for radio fields
        if (!useCheckbox && !values.length) setValues([filterProperties[0]]);
    }, []);

    React.useEffect(() => {
        // reset the values if the filter is deleted
        if (selectedFilters && values.length !== selectedFilters.length) setValues(selectedFilters);
    }, [selectedFilters]);

    const rotate = anchorEl ? 180 : 0;

    return (
        <React.Fragment>
            <Button
                aria-controls={`filter-menu-${name}`}
                aria-haspopup="true"
                onClick={openFilterMenu}
                className={classes.button}
            >
                {translations[name] || name}
                <ArrowDropDownIcon style={{ transform: `rotate(${rotate}deg)`, paddingBottom: rotate ? 0 : '5px', }} />
            </Button>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeFilterMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            >
                <FormGroup style={{ padding: theme.spacing(3), outline: 'none', }}>
                    {filterProperties.map(name => (
                        <React.Fragment key={name}>
                            <FormControlLabel
                                control={
                                    useCheckbox
                                        ? <Checkbox checked={isChecked(values, name)} onChange={handleChange} name={name} style={{ color: 'black', }} />
                                        : <Radio checked={isChecked(values, name)} onChange={handleChange} name={name} id={name} style={{ color: 'black', }} />
                                }
                                label={translations[name] || name}
                            />
                        </React.Fragment>
                    ))}
                </FormGroup>
            </Menu>
        </React.Fragment>
    );
}
