import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';

import theme from '../theme';
import Image from './image';
import Button from './button';
import RichContent from './richContent';

const useStyles = makeStyles(theme => ({
    root: {},
    typo: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
            marginBottom: '3rem !important',
        },
    },
    text: {
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(3),
        },
    },
    floatButton: {
        position: 'absolute',
        zIndex: 400,
        margin: 'auto',
        bottom: '10%',
        right: '10%',
        [theme.breakpoints.up('md')]: {
            bottom: '20%',
            right: '20%',
        },
    },
    standardButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: '300px',
            float: 'right',
        },
    },
}));

const ImageComponent = ({ img, overlap, overlapWithTopElement, alignRight, maxImageHeight, divStyles = {}, }) => (
    <Grid
        item
        container
        lg={alignRight ? 6 : 12}
        justifyContent={alignRight ? 'flex-end' : 'center' }
        style={{ marginTop: overlapWithTopElement ? overlap : 0, }}>
        <Image
            imageName={img}
            textAlign={'right'}
            divStyles={divStyles}
            imageStyles={{
                maxHeight: maxImageHeight,
            }}
        />
    </Grid>
);

export default function BigImage (props) {
    const classes = useStyles(theme);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const {
        name,
        text,
        headline,
        image,
        imageMobile,
        buttonText,
        alignRight,
        overlapWithTopElement,
    } = props;

    let img = imageMobile;
    if (isDesktop) img = image;

    let overlap = 0;
    if (overlapWithTopElement) {
        overlap = '-15vh';
        if (isDesktop) overlap = '-40vh';
    }

    let maxImageHeight = overlapWithTopElement ? '800px' : '600px';
    if (isDesktop && overlapWithTopElement) maxImageHeight = '1200px';

    return (
        <Container id={(name && encodeURI(name))} style={{ padding: 0, }} >
            <Grid container style={{ position: 'relative', }}>

                { img && !isDesktop && (
                    <ImageComponent
                        img={img}
                        overlap={overlap}
                        alignRight={false}
                        overlapWithTopElement={overlapWithTopElement}
                        maxImageHeight={maxImageHeight}

                    />
                )}
                {headline && (
                    <Grid item xs={12}>
                        <Typography className={classes.typo} variant="h2" component="h2">
                            {headline}
                        </Typography>
                    </Grid>
                )}
                {text && (
                    <Grid item xs={12} lg={alignRight ? 6 : 12}>
                        <Typography
                            variant="body1"
                            component="div"
                            className={classes.text}
                        >
                            <RichContent content={text} />
                        </Typography>
                    </Grid>
                )}
                { img && isDesktop && overlapWithTopElement && (
                    <ImageComponent
                        img={img}
                        overlap={overlap}
                        alignRight={alignRight}
                        maxImageHeight={maxImageHeight}
                        overlapWithTopElement={overlapWithTopElement}
                    />
                )}
                {buttonText && (
                    <Grid item xs={12}>
                        <div className={text ? classes.standardButton : classes.floatButton}>
                            <Button fullWidth >
                                {buttonText}
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
