import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '20vh',
    },
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Teaser = ({ mainText1, subText, textColor, backgroundColor, fullWidth, }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    fullWidth = true;
    backgroundColor = '#EFEAE7';

    return (
        <Container className={classes.root} style={{ backgroundColor, maxWidth: fullWidth ? '100vw' : 'inherit', display: 'flex', }}>
            <Grid container item xs={12} className={classes.gridContainer}>
                <Typography component="div" variant="h2" style={{ color: textColor, }} >
                    {mainText1}
                </Typography>
                <Typography component="div" variant="body1" style={{ color: textColor, }} >
                    {subText}
                </Typography>
            </Grid>
        </Container>
    );
};

export default Teaser;
