import React from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';

const useStyles = makeStyles({
    root: {
        width: '100%',
        minHeight: '50vh',
    },
    text: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        color: 'white',
    },
});

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

export default function TextWithBackgroundImage ({ name, backgroundImage, headline, text, subHeadline, }) {
    const classes = useStyles();
    const imageUrl = base + `${backgroundImage}`;

    const content = text.content[0].content[0].value;

    return (
        <div className={classes.root} style={{ backgroundImage: `url(${imageUrl})`, }} id={(name && encodeURI(name)) } >
            <Container >
                <Grid container item xs={12} md={6} className={classes.text}>
                    <Typography variant="h1">
                        {headline}
                    </Typography>
                    {subHeadline && (
                        <Typography className={classes.text} variant="h5" component="div">
                            {subHeadline}
                        </Typography>
                    )}
                    <Typography variant="body1">
                        {content}
                    </Typography>
                </Grid>
            </Container>
        </div>
    );
}
