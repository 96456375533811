import React from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useRouter } from 'next/router';

import Button from '../button';

const useStyles = makeStyles(() => ({
    paperBody: {
        paddingTop: '10vh',
    },
    preText: {
        marginBottom: '0.1em !important',
    },
    typo: {
        marginBottom: '0 !important',
    },
    subTitle: {
        position: 'relative',
    },
    button: {
        position: 'relative',
    },
}));

const TeaserText = ({ teaserConfig, }) => {
    const theme = useTheme();
    const router = useRouter();
    const classes = useStyles(theme);
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const [show, setShow] = React.useState(false);

    const goToLink = () => {
        // only use the onClick handler for the whole teaser if there is no text
        if (!teaserConfig.showTextOnMobile && teaserConfig.linkTarget) {
            router.push(teaserConfig.linkTarget);
        }
    };

    if (!isLarge && !teaserConfig.showTextOnSmallScreens) return null;

    let justify = 'center';
    if (isLarge) justify = teaserConfig.alignLeft ? 'flex-start' : 'flex-end';

    let textColor = 'white';
    if (!teaserConfig.textColor) textColor = 'black';

    let moveToBottom = 0;
    if (!teaserConfig.alignTop) moveToBottom = '10%';

    const textCenterStyle = teaserConfig.centerText ? 'center' : 'left';
    const buttonColor = teaserConfig.buttonColor ? 'white' : 'black';

    React.useState(() => {
        setTimeout(() => setShow(true), 300);
    }, []);

    if (!show) return null;

    return (
        <Container>
            <Grid container spacing={0} justifyContent={justify} >
                <div
                    onClick={goToLink}
                    className={classes.paperBody}
                    style={{ color: textColor, marginTop: moveToBottom, }}>
                    {teaserConfig.preText && (
                        <Typography
                            component="h1"
                            variant="h4"
                            className={classes.preText}
                            style={{ textAlign: textCenterStyle, }}
                        >
                            {teaserConfig.preText}
                        </Typography>
                    )}
                    {teaserConfig.mainText1 && (
                        <Typography component="h2" variant="h1" style={{ textAlign: textCenterStyle, }} className={classes.typo}>
                            {teaserConfig.mainText1}
                        </Typography>
                    )}
                    {teaserConfig.mainText2 && (
                        <Typography component="h3" variant="h2" style={{ textAlign: textCenterStyle, }} className={classes.typo}>
                            {teaserConfig.mainText2}
                        </Typography>
                    )}
                    {teaserConfig.postText && (
                        <Typography
                            component="p"
                            variant="subtitle1"
                            style={{ textAlign: textCenterStyle, }}
                            className={classes.subTitle}>
                            {teaserConfig.postText}
                        </Typography>
                    )}
                    {teaserConfig.buttonText && (
                        <div className={classes.button} style={{ textAlign: textCenterStyle, }}>
                            <Button
                                showIcon={false}
                                onClickFunction={goToLink}
                                type={ buttonColor }
                                buttonStyle={{ paddingLeft: theme.spacing(8), paddingRight: theme.spacing(8), top: teaserConfig.postText ? '-10px' : 15, }}>
                                {teaserConfig.buttonText}
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>
        </Container>
    );
};

export default TeaserText;
