import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Button from '../button';
import useTranslations from '../../hooks/useTranslations';

const useStyles = makeStyles(() => ({
    nextButton: {
        textAlign: 'right',
    },
}));

export function Buttons ({ handleChangeQuestion, alignNextButtonRight = true, }) {
    const classes = useStyles();
    const translations = useTranslations();

    return (
        <Grid container justifyContent='center' item xs={12}>
            <Grid item xs={6}>
                <Button
                    type="black"
                    showIcon={false}
                    data-test="question-back-button"
                    buttonStyle={{
                        padding: '.8rem 3rem',
                        border: '1px solid',
                        borderColor: 'gray',
                    }}
                    onClickFunction={() => handleChangeQuestion(-1)}
                >
                    {translations.prevStepQuiz}
                </Button>
            </Grid>
            <Grid item xs={6} className={alignNextButtonRight && classes.nextButton}>
                <Button
                    type="white"
                    showIcon={false}
                    data-test="question-next-button"
                    buttonStyle={{
                        padding: '.8rem 3rem',
                    }}
                    onClickFunction={() => handleChangeQuestion(1)}>
                    {translations.nextStepQuiz}
                </Button>
            </Grid>
        </Grid>
    );
}

export function BackButton ({ goBack, }) {
    const translations = useTranslations();

    return (
        <Grid item xs={12}>
            <Button
                color="secondary"
                variant="outlined"
                onClick={goBack}
                style={{ textTransform: 'none', }}
            >
                <KeyboardArrowUpIcon />
                <strong>{translations.backToQuiz}</strong>
            </Button>
        </Grid>
    );
}
