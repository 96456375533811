import React from 'react';
import { Container, Grid, Chip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';

import theme from '../theme';
import Spacer from '../components/spacer';
import useDataLayer from '../hooks/useDataLayer';
import ProductBox from '../components/product/productBox';
import ProductFilter from '../components/product/productFilter';
import lowerCaseWithoutWhitespace from '../utils/lowerCaseWithoutWhitespace';

import getTranslatedContent from '../../src/staticPageScripts/getTranslatedContent';
import componentRenderer from '../utils/componentRenderer';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    progressCircle: {
        width: '100%',
        height: '20vh',
        margin: 'auto',
        textAlign: 'center',
        padding: '8vh',
    },
    chipStyle: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    productNumber: {
        textAlign: 'right',
        paddingTop: theme.spacing(1),
    },
}));

const sorting = ['relevance', 'price ascending', 'price descending'];
const filters = [
    // 'product features',
    'goals',
    'product types'];

const sortByPriceAsc = (x, y) => {
    if (x.price < y.price) return -1;
    if (x.price > y.price) return 1;
    return 0;
};

const sortByPriceDesc = (x, y) => {
    if (x.price < y.price) return 1;
    if (x.price > y.price) return -1;
    return 0;
};

const filterProducts = (products, selectedFilters, selectedSorting) => {
    Object.keys(selectedFilters).forEach((key) => {
        key = key.toLowerCase();
        if (key === 'product types' && selectedFilters[key].length) {
            selectedFilters[key] = selectedFilters[key].map(k => k.toLowerCase());
            products = products.filter(p => selectedFilters[key].includes(p.type.toLowerCase()));
        }
        if (key === 'product features' && selectedFilters[key].length) {
            products = products.filter(p => p.productProperties && p.productProperties.some(x => selectedFilters[key].includes(x.toLowerCase())));
        }
        if (key === 'goals' && selectedFilters[key].length) {
            products = products
                .filter(p => p.includes && p.includes.some(x => selectedFilters[key].includes(x[1].toLowerCase())))
                // filter products which are always included
                .filter(p => !p.includes.every(x => x[2] && parseInt(x[2]) > 50));
        }
    });

    Object.values(selectedSorting).forEach(([value]) => {
        if (value === sorting[0]) products = products.sort();
        if (value === 'price ascending') products = products.sort(sortByPriceAsc);
        if (value === 'price descending') products = products.sort(sortByPriceDesc);
    });

    return products;
};

export default function ProductListing(props) {
    const classes = useStyles(theme);
    const router = useRouter();

    const translations = getTranslatedContent('translations', router.locale);
    const shopProducts = getTranslatedContent('shopProducts', router.locale);

    const applyMobileSpacing = useMediaQuery(theme.breakpoints.down('sm'));

    const { products, } = props;

    let isProductListing = true;
    let productData = products;

    const centerListing = Boolean(products.length < 3);

    // if the product listing is part of a page we need to get the product data first
    if (productData[0].sys) {
        isProductListing = false;
        productData = products.map(({ sys, }) => shopProducts.products.find(p => p.id === sys.id));
    }

    productData = productData.filter(n => n);

    const [selectedFilters, setSelectedFilters] = React.useState({});
    const [selectedSorting] = React.useState({});

    const callBackHandler = (x) => {
        const k = Object.keys(x)[0];
        const v = Object.values(x).flat();

        const filters = { ...selectedFilters, };
        filters[k] = v;

        setSelectedFilters(filters);
    };

    const handleFilterDelete = (k, v) => {
        const newFilters = { ...selectedFilters, };
        newFilters[k] = newFilters[k].filter(x => x !== v);
        setSelectedFilters(newFilters);
    };

    const productBoxClick = (product) => {
        useDataLayer('productClick', [product], window.location.pathname);
    };

    let productGoalsFilter = productData
        .filter(p => p.includes)
        .map(p => p.includes)
        .flat()
        .map((p) => {
            if (p[0] === 'goals') return p[1];
            return null;
        })
        .filter(n => n);
    productGoalsFilter = [...new Set(productGoalsFilter)];

    const productTypeFilter = [...new Set(productData.map(p => p.type))]
        // uppercase first letter
        .map(s => s.charAt(0).toUpperCase() + s.slice(1));
    const filterProperties = [productGoalsFilter, productTypeFilter];

    let remainingProducts = filterProducts(productData, selectedFilters, selectedSorting);

    if (remainingProducts.length) {
        remainingProducts = remainingProducts.sort((x, y) => {
            if (x.sortOnProductListing < y.sortOnProductListing) return -1;
            if (x.sortOnProductListing > y.sortOnProductListing) return 1;
            return 0;
        });
    }

    React.useEffect(() => {
        useDataLayer('trackProductImpressions', remainingProducts);
    }, []);

    return (
        <Container spacing={0}>
            <Grid container id={props.name} style={{ justifyContent: centerListing ? 'space-around' : 'flex-start', }} >

                {props.content && (
                    <Grid item xs={12} md={12}>
                        <span className="code-productlisting-pre-text">
                            {componentRenderer(props)}
                        </span>
                    </Grid>
                )}

                {isProductListing && (
                    <Grid item container xs={12} style={{ justifyContent: 'flex-end', }}>
                        {filters.map((f, i) => (
                            <Grid key={f} item xs={6} md={'auto'} >
                                <ProductFilter
                                    name={f}
                                    callBack={callBackHandler}
                                    selectedFilters={selectedFilters[f]}
                                    filterProperties={filterProperties[i]}
                                />
                            </Grid>
                        ))}
                        {/* <Grid item xs={12} md={4}>
                            <ProductFilter
                                name={`${translations.sorting}: ${translations[Object.values(selectedSorting)[0]] || Object.values(selectedSorting)[0]}`}
                                useCheckbox={false}
                                filterProperties={sorting}
                                callBack={setSelectedSorting}
                            />
                        </Grid> */}
                        {/* {<Grid item xs={12} md={'auto'} className={classes.productNumber}>
                            {translations['number of products']}: {remainingProducts.length}
                        </Grid>} */}
                    </Grid>
                )}

                <Grid item xs={12} md={'auto'}>
                    {Object.keys(selectedFilters).map(k => (
                        selectedFilters[k].map(v => (
                            <Chip
                                key={v}
                                className={classes.chipStyle}
                                onDelete={() => handleFilterDelete(k, v)}
                                // search for the original name and show it in the label instead of the filter output (which might be lowercase)
                                label={`${translations[k.toLowerCase()] || k}: ${filterProperties.flat().find(e => lowerCaseWithoutWhitespace(e) === lowerCaseWithoutWhitespace(v))}`} />
                        ))
                    ))}
                </Grid>

                <Spacer spaceMobile={3} />
                {
                    remainingProducts.length > 0
                        ? (
                            remainingProducts.map((p, i) => (
                                <Grid
                                    className="productBox"
                                    container
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    key={p.sku}
                                    spacing={0}
                                    onClick={() => productBoxClick(p)}
                                    style={{ paddingLeft: applyMobileSpacing ? theme.spacing(1) : 0, }}
                                >
                                    <ProductBox product={p} isLast={i === products.length - 1} isFirst={i === 0} isEven={i % 2 === 0} />
                                </Grid>
                            ))
                        ) : (
                            <div>
                                <Spacer spaceMobile={8} />
                                <Typography variant="h5" component="p">
                                    {translations['no products found for this query']}
                                </Typography>
                            </div>
                        )
                }
            </Grid>
        </Container>
    );
}
