import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';

import theme from '../../theme';

const useStyles = makeStyles(theme => ({
    white: {
        backgroundColor: 'white',
        border: '1px solid black',
        height: '30px',
        width: '30px',
    },
    black: {
        border: '1px solid black',
        height: '30px',
        width: '30px',
    },
}));

const one = [
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true
];

const two = [
    false,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    true
];

const three = [
    false,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    true,
    true,
    true
];

const four = [
    false,
    false,
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    true
];

export default function DrawNumber ({ number, }) {
    const classes = useStyles(theme);
    const isTabletOrBigger = useMediaQuery(theme.breakpoints.up('md'));

    let sequence;

    if (number === 1) sequence = one;
    if (number === 2) sequence = two;
    if (number === 3) sequence = three;
    if (number === 4) sequence = four;

    if (!sequence) return null;

    return (
        <Grid container style={{ maxWidth: '200px', marginLeft: isTabletOrBigger ? theme.spacing(6) : 0, }}>
            {sequence.map((s, i) => (
                <React.Fragment key={`section-${i}`} >
                    <span className={s ? classes.white : classes.black} />
                </React.Fragment>
            ))}
        </Grid>
    );
}
