import React from 'react';
import { Container, Paper, useMediaQuery, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useRouter } from 'next/router';

import Spacer from '../spacer';
import Button from '../button';

const getImageUrl = (isMobile = true, image, imageMobile) => {
    const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';
    if (isMobile) return base + imageMobile;
    return base + image;
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        minHeight: '90vh',
    },
    textPaper: {
        minHeight: '50vh',
        textAlign: 'center',
        paddingTop: '20%',
        boxShadow: 'none',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '15%',
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '30%',
            minHeight: '90vh',

        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '30%',
            minHeight: '90vh',
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
    },
    textH1: {
        wordWrap: 'break-word',
        fontSize: '2.1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.3rem',
        },
    },
    textH2: {
        wordWrap: 'break-word',
        fontSize: '1.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.8rem',
        },
    },
    imagePaper: {
        minHeight: '40vh',
        boxShadow: 'none',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.up('md')]: {
            minHeight: '90vh',
        },
    },
    divider: {
        width: '100%',
        borderBottom: '2px solid gray',
    },
}));

const SplitTeaser = ({ text1, text2, cta, image, imageMobile, ctaLink, backgroundColor = '#faf3f3ff', }) => {
    const theme = useTheme();
    const router = useRouter();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isLarge = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Container maxWidth={'xl'} className={classes.root}>
            <Grid container>
                {isLarge && (
                    <Grid item xs={12} md={8}>
                        <Paper className={classes.imagePaper} style={{
                            backgroundImage: `url(${getImageUrl(isMobile, image, imageMobile)})`,
                        }} />
                    </Grid>
                )}
                <Grid item xs={12} md={4}>
                    <Paper className={classes.textPaper} style={{ backgroundColor, }}>
                        <div className={classes.divider} />
                        <Spacer spaceMobile={4} />
                        <Typography className={classes.textH1} variant="h1">
                            {text1}
                        </Typography>
                        {isMobile && (
                            <React.Fragment>
                                <Spacer spaceMobile={1} />
                                <Button
                                    type="white"
                                    showIcon={false}
                                    onClickFunction={() => router.push(ctaLink)}
                                    buttonStyle={{ paddingLeft: theme.spacing(6), paddingRight: theme.spacing(6), }}
                                >
                                    {cta}
                                </Button>
                                <Spacer spaceMobile={5} />
                            </React.Fragment>
                        )}
                        <Typography className={classes.textH2} variant="h3" component="h2">
                            {text2}
                        </Typography>
                        <Spacer spaceMobile={1} />
                        <div className={classes.divider} style={{ width: '75%', margin: 'auto', }} />
                        {!isMobile && (
                            <React.Fragment>
                                <Spacer spaceMobile={3} />
                                <Button
                                    type="white"
                                    showIcon={false}
                                    onClickFunction={() => router.push(ctaLink)}
                                    buttonStyle={{ paddingLeft: theme.spacing(6), paddingRight: theme.spacing(6), }}
                                >
                                    {cta}
                                </Button>
                            </React.Fragment>
                        )}
                    </Paper>
                </Grid>
                {!isLarge && (
                    <Grid item xs={12} md={8}>
                        <Paper className={classes.imagePaper} style={{
                            backgroundImage: `url(${getImageUrl(isMobile, image, imageMobile)})`,
                        }} />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default SplitTeaser;
