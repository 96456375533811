import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';

import theme from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        postWrapper: {
            padding: 0,
            marginBottom: theme.spacing(5),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(0),
            },
        },
        postStyles: {
            margin: 'auto',
            [theme.breakpoints.up('md')]: {
                maxWidth: '94%',
            },
        },
    })
);

export default function RecentInstagramPosts({ post1, post2, post3, headline, }) {
    const classes = useStyles();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    let posts = [post1, post2, post3];
    if (isSm && !isMd) posts = posts.slice(0, 2);

    return (
        <Container>
            <Grid container style={{ margin: 'auto', padding: 0, }} >
                {headline && (
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            {headline}
                        </Typography>
                    </Grid>
                )}
                {posts.map((p, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i} className={classes.postWrapper} >
                        <div className={classes.postStyles} dangerouslySetInnerHTML={{ __html: p, }} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
