import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';

import theme from '../../../theme';
import Button from '../../button';
import { Buttons } from '../buttons';
import Spacer from '../../spacer';
import RichContent from '../../richContent';
import IconsWithRichText from '../../icons/iconWithRichText';

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
    },
}));

export default function QuizIntroPage(props) {
    const classes = useStyles(theme);
    const icons = props.icons && props.icons.length && props.icons.map(i => i.fields);

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isTabletOrBigger = useMediaQuery(theme.breakpoints.up('md'));

    const addBackgroundImage = () => {
        try {
            const backgroundImage = isMobile && props.backgroundImageMobile ? props.backgroundImageMobile : props.backgroundImage;
            document.getElementById('quizPage').style.backgroundImage = `url(${base + backgroundImage})`;
            document.getElementById('quizPage').style.backgroundSize = 'cover';
            document.getElementById('quizPage').style.backgroundPosition = 'center';
        } catch (error) {
            console.error(error);
        }
    };

    const removeBackgroundImage = () => {
        try {
            document.getElementById('quizPage').style.backgroundImage = null;
        } catch (error) {
            console.error(error);
        }
    };

    const startQuiz = () => {
        props.handleChangeQuestion(1);
        removeBackgroundImage();
    };

    React.useEffect(() => {
        if (props.backgroundImage) {
            addBackgroundImage();
            setTimeout(() => {
                if (window.innerWidth > 959) {
                    // window.scrollTo({
                    //     top: 100,
                    //     behavior: 'smooth',
                    // });
                } else {
                    window.scrollTo({
                        top: 1,
                        behavior: 'smooth',
                    });
                }
            }, 300);
        }
        return function cleanup() {
            removeBackgroundImage();
        };
    }, []);

    return (
        <Container>
            <div className={classes.root}>

                <Typography variant="subtitle1" component="h1" style={{ fontSize: isMobile ? '3.5rem' : '5rem', lineHeight: isMobile ? '3.8rem' : '5.3rem', }}>
                    {props.headline}
                </Typography>

                <Spacer spaceMobile={5} />

                {props.firstText && (
                    <RichContent content={props.firstText} />
                )}

                {props.currentStep === 0 && (
                    <React.Fragment>
                        <Spacer spaceMobile={2} />
                        <Button
                            type="white"
                            showIcon={false}
                            data-test="question-next-button"
                            buttonStyle={{
                                padding: '.8rem 3rem',
                            }}
                            onClickFunction={() => startQuiz()} >
                            {props.cta || 'Weiter'}
                        </Button>
                        <Spacer spaceMobile={5} />
                    </React.Fragment>
                )}

                {props.currentStep > 0 && (
                    <Grid container item xs={12}>
                        <Buttons
                            alignNextButtonRight={false}
                            handleChangeQuestion={props.handleChangeQuestion}
                        />
                        <Spacer spaceMobile={2} />
                    </Grid>
                )}

                {props.secondText && (
                    <RichContent content={props.secondText} />
                )}

                <Spacer spaceMobile={5} />

                <Grid container>
                    {icons && icons.length && icons.map(icon => (
                        <IconsWithRichText
                            key={icon.name}
                            icon={icon.icon}
                            text={icon.text}
                            gridXs={3}
                            gridSm={3}
                            gridMd={3}
                            smallText={true}
                            iconSize={isTabletOrBigger ? '80px' : '50px'}
                        />
                    ))}
                </Grid>

            </div>
        </Container>
    );
}
