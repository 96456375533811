import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import Image from 'next/image';

import theme from '../../theme';

const useStyles = makeStyles(theme => ({
    iconWrapper: {
        margin: 'auto',
        textAlign: 'center',
        paddingBottom: theme.spacing(3),
    },
}));

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

export default function IconsInARow (props) {
    const classes = useStyles(theme);

    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

    const icons = [];
    const texts = [];

    let imageSize = '45px';
    if (isSmall) imageSize = '65px';
    if (isLarge) imageSize = '100px';

    Object.entries(props).forEach(([key, value]) => {
        if (key.toLowerCase().includes('icon')) {
            if (key.includes('text')) {
                texts.push(value);
            } else {
                icons.push(value);
            }
        }
        return null;
    });

    return (
        <Container >
            <Grid container>
                {icons.map((icon, i) => (
                    <Grid item xs={props.xs || 3} sm={props.sm || 2} md={props.md || 2} lg={props.lg || 2} key={texts[i] + i} className={classes.iconWrapper}>
                        <Image
                            src={base + icon}
                            alt={texts[i]}
                            height={imageSize}
                            width={imageSize}
                        />
                        {texts[i] && (
                            <Typography variant="body1" component="p" style={{ paddingTop: '10px', fontSize: '0.7rem', }}>
                                {texts[i]}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
