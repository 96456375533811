import React from 'react';
import Image from 'next/image';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';

import theme from '../theme';
import Spacer from './spacer';
import Button from './button';
import RichContent from './richContent';

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';

const useStyles = makeStyles(theme => ({
    image: {
        minWidth: '50vw',
        position: 'relative',
        minHeight: '50vh',
    },
    textAlignAlignImageRight: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(5),
        },
    },
    textAlign: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
    },
}));

export default function ImageTextFullWidth ({ name, image, imageMobile, imageTablet, text, alignImageRight, buttonText, buttonUrl, }) {
    const classes = useStyles(theme);
    const router = useRouter();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    let img = base;

    if (isMobile && imageMobile) img = img + imageMobile;
    else if (isTablet && imageTablet) img = img + imageTablet;
    else img = img + image;

    const buttonClickAction = () => {
        if (buttonUrl) {
            router.push(buttonUrl);
        }
    };

    return (
        <Grid container id={(name && encodeURI(name)) }>
            {(!alignImageRight || isMobile) && (
                <Grid item xs={12} md={6} className={classes.image}>
                    <Image src={img} alt="TheCode Cosmetics" layout='fill' objectFit={'cover'} objectPosition={'center'} />
                </Grid>
            )}
            <Grid container item xs={12} md={6} alignItems="center" className={alignImageRight ? classes.textAlignAlignImageRight : classes.textAlign}>
                {text && (
                    <React.Fragment>
                        <Spacer spaceMobile={3} spaceDesktop={0} spaceTablet={1} />
                        <RichContent containerStyles={{ paddingLeft: 0, paddingRight: 0, }} content={text} />
                        {buttonText && (
                            <Grid item xs={12} >
                                <Button
                                    fullWidth={!isDesktop}
                                    onClick={buttonClickAction}
                                    buttonStyle={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5), }} >
                                    {buttonText}
                                </Button>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {alignImageRight && !isMobile && (
                <Grid item xs={12} md={6} className={classes.image}>
                    <Image src={img} alt="TheCode Cosmetics" layout='fill' objectFit={'cover'} objectPosition={'center'} />
                </Grid>
            )}
        </Grid>
    );
}
