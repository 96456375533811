import Faq from '../components/faq/faq';
import Image from '../components/image';
import Spacer from '../components/spacer';
import Teaser from '../components/teaser';
import QuizSlider from '../components/slider';
import Headline from '../components/headline';
import BigImage from '../components/bigImage';
import FaqStyled from '../components/faq/faqStyled';
import RichContent from '../components/richContent';
import TheCode5050 from '../components/theCode5050';
import ImageAndText from '../components/imageAndText';
import Component5050 from '../components/component5050';
import CustomerReview from '../components/customerReview';
import SlimBanner from '../components/teaser/slimBanner';
import IconsInARow from '../components/icons/iconsInARow';
import ProductListing from '../containers/productListing';
import IconsWrapper from '../components/icons/iconsWrapper';
import SplitTeaser from '../components/teaser/split-teaser';
import QuizIntroPage from '../components/quiz/introPage';
import FacebookTracking from '../components/facebookTracking';
import RichContentSmall from '../components/richContentSmall';
import IconWithText from '../components/icons/iconWithRichText';
import InstagramPosts from '../components/instagram/recentPosts';
import ImageTextFullWidth from '../components/imageTextFullWidth';
import TeaserBannerSlim from '../components/teaser/teaserBannerSlim';
import IconsWithBackground from '../components/icons/iconsWithBackground';
import NewsletterRegistration from '../components/newsletterRegistration';
import InfluencerLandingPage from '../components/influencer/landingPageNl';
import TwoImagesWithBackground from '../components/twoImagesWithBackground';
import TextWithBackgroundImage from '../components/textWithBackgroundImage';
import TeaserBannerSlimText from '../components/teaser/teaserBannerSlimText';

// eslint-disable-next-line complexity
const componentMapping = (componentName) => {
    switch (componentName) {
    case 'headline':
        return Headline;
    case 'spacer':
        return Spacer;
    case 'bigImage':
        return BigImage;
    case 'textImage':
        return ImageAndText;
    case 'textWithBackgroundImage':
        return TextWithBackgroundImage;
    case 'teaser':
        return Teaser;
    case 'productBoxes':
        return ProductListing;
    case 'quizSlider':
        return QuizSlider;
    case 'blogPost':
        return RichContent;
    case 'image':
        return Image;
    case 'iconsWithBackground':
        return IconsWithBackground;
    case 'twoImagesWithBoxesAndBackground':
        return TwoImagesWithBackground;
    case 'textSmall':
        return RichContentSmall;
    case 'faqWrapper':
        return Faq;
    case 'faq':
        return FaqStyled;
    case 'component5050':
        return Component5050;
    case 'teaserBannerSlim':
        return TeaserBannerSlim;
    case 'instagramPosts':
        return InstagramPosts;
    case 'facebookTracking':
        return FacebookTracking;
    case 'newsletterRegistration':
        return NewsletterRegistration;
    case 'slimBanner':
        return SlimBanner;
    case 'iconWithRichText':
        return IconWithText;
    case 'influencerDiscountCodeNewsletter':
        return InfluencerLandingPage;
    case 'iconsInARow':
        return IconsInARow;
    case 'splitTeaser':
        return SplitTeaser;
    case 'quizIntroPage':
        return QuizIntroPage;
    case 'fullWidthImageText':
        return ImageTextFullWidth;
    case 'teaserBannerSlimText':
        return TeaserBannerSlimText;
    case 'theCode5050':
        return TheCode5050;
    case 'iconsWrapper':
        return IconsWrapper;
    case 'customerReview':
        return CustomerReview;
    default:
        console.error('No component found for', componentName);
    }
};

export default componentMapping;
