import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import theme from '../../theme';
import Spacer from '../spacer';
import Headline from '../headline';
import RichContent from '../richContent';

const useStyles = makeStyles({
    root: {
        width: '100%',
        margin: 'auto',
    },
});

export default function Faq (props) {
    const classes = useStyles(theme);

    return (
        <Container>
            <div className={classes.root} id={(props.name && encodeURI(props.name)) }>
                {props.contents.map((c, i) => {
                    if (c.fields.type) {
                        return (
                            <React.Fragment key={`${c.fields.name}-${i}`}>
                                {i !== 0 && (<Spacer spaceMobile={5} />)}
                                <Headline {...c.fields} containerStyles={{ padding: 0, }} />
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <Accordion key={`${c.fields.name}-${i}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>{c.fields.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RichContent
                                        content={c.fields.answer}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    }
                })}
            </div>
        </Container>
    );
}
