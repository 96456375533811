import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../theme';

const useStyles = makeStyles(theme => ({
    elementLeft: {
        marginTop: theme.spacing(5),
        minHeight: '180px',
    },
    elementLeftText: {
        borderRight: '3px solid black',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    elementRight: {
        marginTop: theme.spacing(5),
        minHeight: '180px',
    },
    elementRightText: {
        borderLeft: '3px solid black',
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    hrElementTop: {
        width: '50%',
        top: '-8px',
        position: 'relative',
        borderWidth: '3px',
        borderStyle: 'solid',
    },
}));

const TheCode5050 = ({ textLeft, textRight, borderColor, }) => {
    const classes = useStyles(theme);

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} className={classes.elementLeft}>
                    <hr className={classes.hrElementTop} style={{ borderColor, float: 'right', }} />
                    <Typography variant="body1" className={classes.elementLeftText} style={{ borderColor, }}>
                        {textLeft}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.elementRight}>
                    <hr className={classes.hrElementTop} style={{ borderColor, float: 'left', }}/>
                    <Typography variant="body1" className={classes.elementRightText} style={{ borderColor, }} >
                        {textRight}
                    </Typography>
                    <hr className={classes.hrElementTop} style={{ borderColor, float: 'left', }}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default TheCode5050;
