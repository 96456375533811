import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../theme';
import richContentMapper from '../utils/richContentMapper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    text: theme.typography.body2,
}));

export default function RichContent (props) {
    const classes = useStyles(theme);
    const html = richContentMapper(props.content);

    return (
        <Container>
            <div className={classes.root} style={props.styles || {} } id={(props.name && encodeURI(props.name)) }>
                <Grid container item xs={12}>
                    <div className={classes.text} dangerouslySetInnerHTML={{ __html: html, }} />
                </Grid>
            </div>
        </Container>
    );
}
