import React from 'react';
import { Container, Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import TeaserText from './teaserText';

const getImageUrl = (isMobile = true, image, imageMobile) => {
    const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';
    if (isMobile) return base + imageMobile;
    return base + image;
};

const useStyles = makeStyles(theme => ({
    paperContainer: {
        width: '100%',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 0,
        color: 'white',
    },
}));

const Teaser = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { image, imageMobile, name, hasMaxWidth, hasMaxHeight, } = props;

    return (
        <Container maxWidth={hasMaxWidth ? 'lg' : 'xl' } style={{ padding: 0, }}>
            <Paper
                className={classes.paperContainer}
                id={(name && encodeURI(name)) }
                style={{
                    minHeight: hasMaxHeight ? '50vh' : '60vh',
                    maxHeight: !isMobile && hasMaxHeight ? '50vh' : '100%',
                    backgroundImage: `url(${getImageUrl(isMobile, image, imageMobile)})`,
                }}>
                <TeaserText teaserConfig={props} />
            </Paper>
        </Container>
    );
};

export default Teaser;
