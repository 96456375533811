/* eslint-disable complexity */
/* eslint-disable no-empty-function */
import React from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

import Image from '../image';
import Button from '../button';
import RichContent from '../richContent';

import theme from '../../theme';
import formatCurrency from '../../utils/formatCurrency';

import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';

const useStyles = makeStyles(theme => ({
    root: {},
    text: theme.typography.body1,
    showDescriptionText: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: 'center',
    },
    productName: {
        display: 'flex',
        minHeight: '60px',
        alignItems: 'center',
        marginBottom: '0 !important',
    },
    textWrapper: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(2),
        },
    },
    productPriceWraper: {
        fontSize: '0.9rem',
    },
    productPrice: {
        color: '#e34b4b',
        fontWeight: 'bold',
    },
    rrpWrapper: {
        paddingLeft: '5px',
    },
    rrp: {
        textDecoration: 'line-through',
        paddingLeft: '5px',
    },
}));

export default function ProductBox({ product, isFirst, isLast, showButton = true, strikePrice = false, showRrp = true, imageHeight = '360px', }) {
    const router = useRouter();
    const classes = useStyles(theme);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    let minHeightDesc = '130px';
    if (isMd) minHeightDesc = '90px';
    if (isLg) minHeightDesc = '60px';

    const minHeightSubTitle = '20px';

    const translations = getTranslatedContent('translations', router.locale);
    const url = product.url && product.url.replace('/', '').replace('product', '');

    return (
        <Grid item xs={12} id={(product.name && encodeURI(product.name))}>
            <div className={classes.root}>
                {url ? (
                    <NextLink href={{
                        pathname: '/product/[productName]',
                        query: { productName: url, },
                    }} passHref>
                        <a style={{ textDecoration: 'none', }} href={url}>
                            <Image imageStyles={{ height: isMd ? imageHeight : '200px', }} imageName={product.productImages[0]} />
                        </a>
                    </NextLink>
                )
                    : <Image imageStyles={{ height: isMd ? imageHeight : '200px', }} imageName={product.productImages ? product.productImages[0] : product.image} />
                }
                <div className={classes.textWrapper}>
                    <Typography variant="h5" component="p" className={classes.productName}>
                        <strong >
                            {product.name}
                        </strong>
                    </Typography>
                    <Typography style={{ fontSize: '0.9rem', minHeight: minHeightSubTitle, }} >
                        {product.subtitle}
                    </Typography>
                    <br />
                    {!isMobile && (
                        <React.Fragment>
                            {product.description &&
                                <RichContent
                                    content={product.description}
                                    textStyles={{ fontSize: '0.9rem', }}
                                    containerStyles={{ padding: 0, minHeight: minHeightDesc, }} />
                            }
                            {product.productDescriptionShort &&
                                <RichContent
                                    content={product.productDescriptionShort}
                                    textStyles={{ fontSize: '0.9rem', }}
                                    containerStyles={{ padding: 0, minHeight: minHeightDesc, }} />
                            }
                        </React.Fragment>
                    )}
                    <Typography component="div" className={classes.productPriceWraper} style={{ textDecoration: strikePrice ? 'line-through' : '' }}>
                        {product.price && translations['price from']}
                        {product.price && (
                            <span className={classes.productPrice}>
                                {formatCurrency.format(product.price / 100)}
                            </span>
                        )}
                        {showRrp && product.rrp && (
                            <span className={classes.rrpWrapper}>
                                {translations['sale-price-note']}
                                <span className={classes.rrp}>
                                    {formatCurrency.format(product.rrp / 100)}
                                </span>
                            </span>
                        )}
                    </Typography>
                    {showButton && (
                        <React.Fragment>
                            <div style={{ height: '1rem', width: '100%', }} />
                            <NextLink href={{
                                pathname: '/product/[productName]',
                                query: { productName: url, },
                            }} passHref>
                                <a style={{ textDecoration: 'none', }} href={url}>
                                    <Button
                                        fullWidth
                                        onClickFunction={() => {
                                            //
                                        }}
                                        buttonStyle={{}}
                                    >
                                        {translations.productBoxButton}
                                    </Button>
                                </a>
                            </NextLink>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {!isLast && <div style={{ height: theme.spacing(8), width: '100%', }} />}
        </Grid>
    );
}
