import React from 'react';
import NextLink from 'next/link';
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const getImageUrl = (isMobile = true, image, imageMobile) => {
    const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';
    if (isMobile) return base + imageMobile;
    return base + image;
};

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 0,
        color: 'white',
        cursor: 'pointer',
        minWidth: '90vw',
        minHeight: '15vh',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            minHeight: '300px',
            minWidth: '100%',
            margin: 'auto',
        },
    },
}));

const Teaser = ({ name, bannerImage, bannerImageMobile, url, }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Container className={classes.root}>
            <Grid container item xs={12}>
                <NextLink href={url}>
                    <div
                        className={classes.container}
                        id={(name && encodeURI(name)) }
                        style={{
                            backgroundImage: `url(${getImageUrl(isMobile, bannerImage, bannerImageMobile)})`,
                        }}>
                    </div>
                </NextLink>
            </Grid>
        </Container>
    );
};

export default Teaser;
