import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, CircularProgress, useMediaQuery } from '@material-ui/core';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Context } from '../../../src/context/appContext';

import theme from '../../theme';
import Spacer from '../spacer';
import Button from '../button';
import Logo from '../../../public/assets/logo-thecode-black.png';
import getTranslatedContent from '../../staticPageScripts/getTranslatedContent';
import handleNewsletterRegistration from '../../utils/handleNewsletterRegistration';
import { addDiscountCodeToCart } from '../../services/shopify';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
    },
    contentContainer: {
        textAlign: 'center',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8),
        },
    },
    logo: {
        position: 'relative',
        width: '18vw',
        margin: 'auto',
    },
    textLarge: {
        fontSize: '200%',
    },
    textSmall: {
        fontSize: '80%',
    },
    code: {
        fontSize: '120%',
        border: '3px solid black',
        textTransform: 'uppercase',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            fontSize: '240%',
        },
    },
    form: {
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
    },
    arrowIconWrapper: {
        position: 'absolute',
        top: '90%',
        borderRadius: '50%',
        height: '4rem',
        width: '4rem',
        border: '1px grey solid',
        background: 'grey',
        opacity: '0.8',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    arrowIcon: {
        width: '3rem',
        height: '3rem',
        color: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15%',
        left: 0,
        right: 0,

    },
}));

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

const LandingPageNl = ({ image, text1, text2, discountCode, successText, buttonSuccessText, ctaText, btnCtaText, conditions, sibAttribute, }) => {
    const router = useRouter();
    const classes = useStyles(theme);
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));

    const { state, updateState, } = React.useContext(Context);
    const translations = getTranslatedContent('translations', router.locale);

    image = base + image;

    const [emailAddress, setEmailAddress] = React.useState(undefined);
    const [error, setShowError] = React.useState(undefined);
    const [signupSuccess, setSignupSuccess] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    const changeEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    const addDiscountCode = async () => {
        const shopifyCheckoutToken = await addDiscountCodeToCart(state.cart.shopifyCheckoutToken, discountCode);
        const newState = { ...state, };
        newState.cart.shopifyCheckoutToken = shopifyCheckoutToken;
        updateState(newState);
    };

    const handleRegistration = async () => {
        setLoading(true);

        // Newsletter registration
        await handleNewsletterRegistration(emailAddress, setSignupSuccess, setShowError, router.locale);
        await fetch('/api/email/updateContact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailAddress,
                campaign: sibAttribute,
            }),
        })
            .then(r => r.json())
            .catch(e => console.error(e));

        const ele = document.getElementById('newsletterEmailInput');
        if (ele) {
            ele.value = null;
            setEmailAddress(null);
        }
        // Apply discount code
        await addDiscountCode();

        setLoading(false);
    };

    return (
        <Grid container className={classes.root} alignItems="center">
            <Grid item xs={12} md={6}>
                <div style={{ position: 'relative', height: isLarge ? '90vh' : '60vh', width: isLarge ? '45vw' : '100vw', }}>
                    <Image placeholder="blur" blurDataURL={image.replace('q_auto', 'q_10')} src={image} alt="Alt Text" layout="fill" />
                    <div className={classes.arrowIconWrapper}>
                        <ArrowDropDownIcon className={classes.arrowIcon} style={{ transform: 'rotate(0)deg)', }} />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} >
                <div className={classes.contentContainer}>
                    <Spacer spaceMobile={5} />
                    {isLarge && (
                        <React.Fragment>
                            <div className={classes.logo}>
                                <Image src={Logo} alt="TheCode Cosmetics" />
                            </div>
                            <Spacer spaceMobile={1} />
                        </React.Fragment>
                    )}
                    <Typography component={'p'} variant="h3">
                        <strong>{text1}</strong>
                    </Typography>
                    <Typography component={'p'} className={classes.textLarge}>
                        {text2}
                    </Typography>
                    <Spacer spaceMobile={5} />
                    {signupSuccess && (
                        <React.Fragment>
                            <Typography>
                                <strong>{successText}</strong>
                            </Typography>
                            <Spacer spaceMobile={1} />
                            <div className={classes.code}>
                                {discountCode}
                            </div>
                            <Spacer spaceMobile={1} />
                            <Button onClickFunction={() => router.push('/the-shop')} fullWidth type="black">{buttonSuccessText}</Button>
                        </React.Fragment>
                    )}
                    {!signupSuccess && (
                        <React.Fragment>
                            <Typography component={'p'}>
                                {ctaText}
                            </Typography>
                            <Spacer spaceMobile={3} />
                            <div className={classes.form}>
                                <form
                                    noValidate
                                    autoComplete="email"
                                    id="newsletterRegistrationForm"
                                    onSubmit={e => e.preventDefault()}
                                >
                                    <TextField
                                        error={Boolean(error)}
                                        label={translations.insertEmailAddressFooter}
                                        type="email"
                                        id="newsletterEmailInput"
                                        fullWidth
                                        onChange={changeEmailAddress}
                                        variant="standard"
                                        autoComplete="on"
                                        helperText={error || ''}
                                        InputLabelProps={{
                                            style: {
                                                color: 'gray',
                                            },
                                        }}
                                    />
                                </form>
                                <Spacer spaceMobile={1} />
                                {loading
                                    ? <CircularProgress color="inherit" />
                                    : <Button onClickFunction={handleRegistration} fullWidth type="black">
                                        {btnCtaText}
                                    </Button>}
                            </div>
                        </React.Fragment>
                    )}
                    <Spacer spaceMobile={5} />
                    <Typography component={'p'} className={classes.textSmall}>
                        {conditions}
                        <NextLink href="/privacy-policy">
                            <a style={{ textDecoration: 'none', paddingLeft: '3px', }}>
                                {translations.newsletterPolicyText}
                            </a>
                        </NextLink>
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

export default LandingPageNl
    ;
