import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import theme from '../theme';
import Image from './image';
import RichContent from './richContent';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
    },
    imageMobile: {
        display: 'block',
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    imageDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
}));

export default function Component5050 (props) {
    const classes = useStyles(theme);

    return (
        <Container id={(props.name && encodeURI(props.name))} className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.imageMobile}>
                    <Image
                        imageName={props.image.fields.image}
                        alt={props.image.fields.imageAltText}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RichContent content={props.text} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.imageDesktop}>
                    <Image
                        imageName={props.image.fields.image}
                        alt={props.image.fields.imageAltText}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
