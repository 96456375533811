import React from 'react';
import { NextSeo } from 'next-seo';

import componentMapping from './componentMapping';

export default function ComponentRenderer (props) {
    const pageComponents = props.content
        .map(c => ({
            component: componentMapping(c.contentType),
            ...c,
        }))
        .filter(n => n.component);

    return (
        <div>
            <NextSeo
                title={props.seoTitle}
                description={props.seoDescription || ''}
                canonical={'https://www.thecodecosmetics.com/' + props.canonical}
            />

            {pageComponents.map((c, i) => (
                React.createElement(c.component, {
                    key: `${c.id}+${i}`,
                    ...c,
                })
            ))}
        </div>
    );
}
