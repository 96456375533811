import React from 'react';
import { Container, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const getImageUrl = (isMobile = true, image, imageMobile) => {
    const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';
    if (isMobile) return base + imageMobile;
    return base + image;
};

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        width: '100%',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: 0,
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3),
        },
    },
    preText: {
        fontSize: '1.3rem',
        marginBottom: '0.1em !important',
    },
    paperBody: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: theme.spacing(3),
        },
        paddingBottom: 0,
        paddingTop: theme.spacing(8),
    },
    typo: {
        marginBottom: '0 !important',
    },
}));

const Teaser = ({ image, imageMobile, name, preText, mainText1, mainText2, postText, textColor, alignLeft, centerText, }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    let textAlign = 'center';
    if (!centerText) {
        textAlign = alignLeft ? 'left' : 'right';
    }

    return (
        <Container className={classes.root}>
            <Grid container item xs={12}>
                <div
                    className={classes.container}
                    id={(name && encodeURI(name)) }
                    style={{
                        minHeight: isMobile ? '100%' : '300px',
                        backgroundImage: `url(${getImageUrl(isMobile, image, imageMobile)})`,
                    }}>
                    <div
                        className={classes.paperBody}
                        style={{ color: textColor ? 'white' : 'black', }}>
                        {preText && (
                            <Typography
                                component="h1"
                                variant="body1"
                                className={classes.preText}
                                style={{ textAlign, }}
                            >
                                {preText}
                            </Typography>
                        )}
                        {mainText1 && (
                            <Typography component="h2" variant="h2" style={{ textAlign, }} className={classes.typo}>
                                {mainText1}
                            </Typography>
                        )}
                        {mainText2 && (
                            <Typography component="h2" variant="h2" style={{ textAlign, }} className={classes.typo}>
                                {mainText2}
                            </Typography>
                        )}
                        {postText && (
                            <Typography
                                component="h2"
                                variant="subtitle1"
                                style={{ textAlign, }}
                                className={classes.subTitle}>
                                {postText}
                            </Typography>
                        )}
                    </div>

                </div>
            </Grid>
        </Container>
    );
};

export default Teaser;
