import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import Image from 'next/image';

import theme from '../../theme';
import Spacer from '../spacer';
import RichContent from '../richContent';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    content: {
        padding: 0,
        paddingTop: theme.spacing(8),
    },
}));

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

export default function IconsWithBackground (props) {
    const classes = useStyles(theme);
    const headlineType = 'h' + props.headlineType;
    const imageUrl = base + `${props.backgroundImage}`;
    const { iconList, iconTextList, } = props;

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const icons = iconList.map((icon, i) => ({
        url: icon,
        text: iconTextList[i],
    }));

    return (
        <Container className={classes.root} maxWidth={props.fullWidth ? 'xl' : 'lg' } style={{ backgroundImage: `url(${imageUrl})`, }} id={(props.name && encodeURI(props.name)) } >
            <Container className={classes.content}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'center', paddingTop: theme.spacing(4), }}>
                        <Typography
                            variant={headlineType}
                            component={headlineType}
                            gutterBottom>
                            {props.headlineText}
                        </Typography>
                    </Grid>
                    <Spacer spaceMobile={3} />
                    {props.subHeadline && (
                        <Grid item xs={12} style={{ textAlign: 'center', }}>
                            <Typography
                                variant="subtitle1"
                                component="p"
                            >
                                {props.subHeadline}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item container xs={12} style={{ textAlign: 'center', }}>
                        {icons.map(icon => (
                            <Grid item xs={4} key={icon.text}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingBottom: theme.spacing(12),
                                }}>
                                    <Image
                                        alt={icon.text}
                                        src={base + icon.url}
                                        width={isMobile ? '60px' : '80px'}
                                        height={isMobile ? '60px' : '80px'}
                                    />
                                </div>
                                <div style={{ position: 'relative', top: '-60px', }}>
                                    <strong style={{ fontSize: isMobile ? '0.7rem' : '0.9rem', }}>
                                        {icon.text}
                                    </strong>
                                </div>

                            </Grid>
                        ))}
                    </Grid>

                    {props.richText && (
                        <Grid item xs={12} style={{ textAlign: 'center', margin: 'auto', }}>
                            <Typography
                                variant="body1"
                                component="div"
                            >
                                <RichContent content={props.richText} />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Container>
    );
}
