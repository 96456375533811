import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Grid, Typography, Container, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@material-ui/core';

import theme from '../../theme';
import Spacer from '../spacer';
import RichContent from '../richContent';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 'auto',
    },
    faqItem: {
        minHeight: '50px',
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.black,
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginRight: 0,
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: 'inherit',
        },
    },
    faqArrow: {
        float: 'right',
        height: '50px',
        paddingTop: '12px',
        zIndex: 5000,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.black,
        [theme.breakpoints.up('sm')]: {
            height: '50px',
        },
    },
    faqText: {
        maxHeight: 0,
        display: 'none',
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-out',
        marginTop: theme.spacing(2),
        '&.active': {
            maxHeight: '50px',
        },
    },
    faqContainer: {
    },
    faqWrapper: {
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
        },
    },
    image: {
        zIndex: -1,
        width: '100%',
        minHeight: '20vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: {
            minHeight: '30vh',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '50vh',
        },
    },
}));

const baseUrl = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,g_xy_center,q_auto/';

export default function FaqStyled (props) {
    const classes = useStyles(theme);
    const [expanded, setExpanded] = React.useState(null);

    const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (i) => {
        if (expanded === i) setExpanded(null);
        else setExpanded(i);
    };

    const questions = props.questions.filter((q, i) => props.answers[i] && props.answers[i].fields && props.answers[i].fields.content);

    return (
        <Container className={classes.root} >
            <Grid container >
                {props.imageMobile && isNotDesktop && (
                    <React.Fragment>
                        <Grid container item xs={12} >
                            <div
                                className={classes.image}
                                style={{ backgroundImage: `url(${baseUrl + props.image})`, }}
                            />
                        </Grid>
                        <Spacer spaceMobile={3} />
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <Typography variant="h2" style={{ margin: 0, marginBottom: 0, }}>
                        {props.headline}
                    </Typography>
                    {props.text && (
                        <RichContent
                            content={props.text}
                            containerStyles={{ padding: 0, }}
                        />
                    )}
                </Grid>
            </Grid>
            {isNotDesktop && <Spacer spaceMobile={1} />}
            <Grid container item xs={12} alignItems={'center'} className={classes.faqContainer}>
                {props.image && !isNotDesktop && !props.imageRight && (
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        style={{
                            backgroundImage: `url(${baseUrl + props.image})`,
                        }}
                        className={classes.image}
                    />
                )}
                <Grid item xs={12} lg={6}>
                    {questions.map((q, i) => (
                        <React.Fragment key={q}>
                            <Grid container item xs={12} className={classes.faqWrapper} style={{ paddingLeft: theme.spacing(props.imageRight || isNotDesktop ? 0 : 3), }}>
                                <Grid item xs={11}>
                                    <Accordion square expanded={expanded === i} onChange={() => handleChange(i)} className={classes.faqItem}>
                                        <AccordionSummary aria-controls="panel-content">
                                            <Typography variant="body1" component="div">
                                                {q}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <RichContent
                                                content={props.answers[i].fields.content}
                                                containerStyles={{ padding: 0, }}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className={classes.faqArrow} onClick={() => handleChange(i)}>
                                        <ArrowDropDownIcon style={{ transform: `rotate(${expanded === i ? 180 : 0}deg)`, }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {props.image && !isNotDesktop && props.imageRight && (
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        style={{
                            backgroundImage: `url(${baseUrl + props.image})`,
                        }}
                        className={classes.image}
                    />
                )}
            </Grid>
        </Container>
    );
}
