import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSwipeable } from 'react-swipeable';

import theme from '../../theme';
import DrawNumber from './drawNumber';
import RichContent from '../richContent';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(3)}px 0`,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.black,
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(8),
        },
    },
    largeScreenHeight: {
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            minHeight: '30vh',
        },
    },
    icon: {
        textAlign: 'right',
        marginLeft: '1rem',
        fontSize: '2.5rem',
        border: '1px solid white',
        transform: 'rotate(90deg)',
    },
    arrowIcon: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    number: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up(321)]: {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.up(425)]: {
            marginLeft: theme.spacing(5),
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(0),
        },
    },
    textAlignment: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(5),
            paddingTop: theme.spacing(0),
        },
    },
    box: {
        display: 'inline-block',
        border: '1px solid white',
        marginRight: '1rem',
        marginLeft: '1rem',
        height: '15px',
        width: '15px',
    },
    mobileSliderNav: {
        textAlign: 'center',
    },
}));

export default function QuizSlider (props) {
    const classes = useStyles(theme);
    const [index, setIndex] = React.useState(0);

    const changeIndex = (delta) => {
        const newIndex = index + delta;
        if (newIndex < 1) setIndex(0);
        else if (newIndex > 3) setIndex(3);
        else setIndex(newIndex);
    };

    const content = [0, 1, 2, 3].map((_, i) => ({
        headline: props['headlineSlide' + (i + 1)],
        subHeadline: props['subHeadline' + (i + 1)],
        content: props['text' + (i + 1)],
    }));

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (eventData.dir === 'Left') {
                changeIndex(1);
            }
            if (eventData.dir === 'Right') {
                changeIndex(-1);
            }
        },
    });

    return (
        <div className={classes.root}>
            <Container id={(props.name && encodeURI(props.name)) }>
                <Grid container {...handlers}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="p">
                            <strong>{props.headline}</strong>
                        </Typography>
                    </Grid>
                    <Grid xs={12} container item alignItems="center" className={classes.largeScreenHeight}>
                        <Grid item xs={12} md={1} className={classes.arrowIcon}>
                            <KeyboardArrowUpIcon
                                className={classes.icon} style={{ transform: 'rotate(270deg', }}
                                onClick={() => changeIndex(-1)} />
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.number}>
                            <DrawNumber number={index + 1} />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <div className={classes.textAlignment}>
                                <Typography variant="h4" component="p">
                                    <strong>{content[index].headline}</strong>
                                </Typography>
                                <Typography variant="h4" component="p">
                                    <strong>{content[index].subHeadline}</strong>
                                </Typography>
                                <br/>
                                <Typography variant="body1" component="p">
                                    <RichContent
                                        content={content[index].content}
                                        containerStyles={{ padding: 0, }}
                                    />
                                </Typography>
                                <br/>
                                <br/>
                            </div>
                            <div className={classes.mobileSliderNav}>
                                {content.map((_, i) => (
                                    <span
                                        key={i}
                                        className={classes.box}
                                        onClick={() => setIndex(i)}
                                        style={i === index ? { backgroundColor: 'white', } : {}} />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} className={classes.arrowIcon}>
                            <div>
                                <KeyboardArrowUpIcon
                                    className={classes.icon}
                                    onClick={() => changeIndex(1)} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
