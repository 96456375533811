import React from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../theme';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function Headline ({ name, headlineText, type, inHandletters, centerText, containerStyles = { padding: 0, }, }) {
    const classes = useStyles();
    const headlineType = 'h' + type;

    return (
        <Container style={containerStyles}>
            <div className={classes.root} id={(name && encodeURI(name)) }>
                <Grid container item xs={12} >
                    <Typography
                        variant={headlineType}
                        component={headlineType}
                        style={{
                            width: '100%',
                            textAlign: centerText ? 'center' : 'left',
                            fontFamily: inHandletters ? theme.typography.subtitle1.fontFamily : theme.typography[headlineType].fontFamily,
                        }}
                    >
                        {headlineText}
                    </Typography>
                </Grid>
            </div>
        </Container>
    );
}
