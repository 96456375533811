/* eslint-disable multiline-ternary */
/* eslint-disable complexity */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';

import Image from './image';
import Button from './button';
import theme from '../theme';
import RichContent from './richContent';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 'grow',
        position: 'relative',
    },
    headline: {
        top: '1%',
        zIndex: 400,
        fontSize: '3rem',
        lineHeight: '3rem',
        maxWidth: '350px',
        textAlign: 'left',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            left: '15%',
            zIndex: 400,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            left: '12%',
            maxWidth: '35%',
            top: '6%',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4rem',
            lineHeight: '4rem',
            top: '1.5%',
        },
    },
    headlineWrapper: {
        zIndex: 400,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            height: '80px',
        },
    },
    iconsWrapper: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            position: 'absolute',
            zIndex: 400,
            left: '20%',
            bottom: '6%',
        },
    },
    buttonWrapperWithQRCodes: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            zIndex: 400,
            bottom: '8%',
            marginLeft: theme.spacing(42),
        },
    },
    buttonWrapper: {
        position: 'relative',
        right: '-107%',
        top: '-90px',
    },
    svgIcon: {
        fontSize: '6rem',
        color: theme.palette.background.black,
    },
}));

export default function ImageAndText (props) {
    const router = useRouter();
    const classes = useStyles(theme);

    const isTablet = useMediaQuery(theme.breakpoints.up('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileAlignment = useMediaQuery('(max-width:959px)');

    // contentful mapper
    if (props.props) {
        let alignRight = !props.imageLeft;
        // these options do not allow for alignRight
        if (props.textBeneath || props.smallText || props.showQrCodes) {
            alignRight = false;
        }
        props = props.props;
        props.alignRight = alignRight;
    }

    let alignRight = props.alignRight;
    if (mobileAlignment && props.imageTopOnMobile) {
        alignRight = false;
    }

    const buttonClickAction = () => {
        if (props.buttonUrl) {
            router.push(props.buttonUrl);
        }
    };

    const displayImage = () => (
        <Grid item xs={12} md={6} align="center">
            <Image
                isProduct={props.isProduct}
                imageName={props.image}
                alt={props.imageAltText}
                imageStyles={props.imageStyles}
            />
        </Grid>
    );

    const displayContent = () => (
        <React.Fragment>
            <Grid item xs={12} md={6} className={classes.gridPadding} style={{ margin: 'auto', }}>
                {!isDesktop && <br/>}
                {props.subHeadline && (
                    <Typography component="h3" variant="h4">
                        {props.subHeadline }
                    </Typography>
                )}
                {props.text1 && (
                    <Typography variant="body1" component="div">
                        <RichContent containerStyles={{ paddingLeft: 0, paddingRight: 0, }} content={props.text1} />
                    </Typography>
                )}
                {props.subtitle && (
                    <React.Fragment>
                        <Typography variant="subtitle1" component="p" style={{ marginBottom: 0, }}>
                            {props.subtitle}
                        </Typography>
                        <br/>
                    </React.Fragment>
                )}
                {props.text2 && (
                    <Typography variant="body1" component="div">
                        <RichContent containerStyles={{ paddingLeft: 0, paddingRight: 0, }} content={props.text2} />
                    </Typography>
                )}
                {isDesktop && props.buttonText && !props.showQrCodes && !props.smallText && (
                    <Grid item xs={12} >
                        <Button
                            fullWidth={!isDesktop}
                            onClick={buttonClickAction}
                            buttonStyle={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5), }} >
                            {props.buttonText}
                        </Button>
                    </Grid>
                )}
                {!isDesktop && (
                    <React.Fragment>
                        {props.textBeneath && (
                            <Typography variant="body1" component="div">
                                <RichContent containerStyles={{ paddingLeft: 0, paddingRight: 0, }} content={props.textBeneath} />
                            </Typography>
                        )}
                        {props.smallText && (
                            <React.Fragment>
                                <Typography variant="body2" component="p">
                                    {props.smallText}
                                </Typography>
                            </React.Fragment>
                        )}
                        {props.buttonText && (
                            <Button fullWidth={!isDesktop} onClick={buttonClickAction} >
                                {props.buttonText}
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {isDesktop && (props.textBeneath || props.smallText || props.showQrCodes || props.buttonText) && (
                <React.Fragment>
                    <Grid container item xs={12} style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), }}>
                        {props.textBeneath && (
                            <Grid item xs={12} >
                                <Typography variant="body1" component="div">
                                    <RichContent containerStyles={{ paddingLeft: 0, paddingRight: 0, }} content={props.textBeneath} />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item xs={12} md={6} style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), }}>
                        {props.smallText && (
                            <Grid item xs={12} style={{ paddingBottom: theme.spacing(props.showQrCodes ? 0 : 3), }}>
                                <Typography variant="body2" component="div">
                                    {props.smallText}
                                </Typography>
                            </Grid>
                        )}
                        {props.buttonText && props.showQrCodes && (
                            <Grid item md={props.showQrCodes ? 6 : 12} style={{ paddingTop: theme.spacing(props.showQrCodes ? 8 : 0), }}>
                                <div className={props.showQrCodes ? classes.buttonWrapperWithQRCodes : classes.buttonWrapper}>
                                    <Button
                                        fullWidth={!isDesktop}
                                        onClick={buttonClickAction}
                                        buttonStyle={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5), }} >
                                        {props.buttonText}
                                    </Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );

    return (
        <Container id={(props.name && encodeURI(props.name)) }>
            <Grid container className={classes.root} spacing={isTablet ? 5 : 0}>
                {props.headline && (
                    <Grid item xs={12} className={classes.headlineWrapper}>
                        <Typography
                            component="h2"
                            className={classes.headline}
                            style={{ position: props.subHeadline && !isDesktop ? 'inherit' : 'absolute', }}
                        >
                            {props.headline}
                        </Typography>
                    </Grid>
                )}
                {alignRight ? (
                    <React.Fragment>
                        {displayContent()}
                        {displayImage()}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {displayImage()}
                        {displayContent()}
                    </React.Fragment>
                )}
            </Grid>
        </Container>
    );
}
