import React from 'react';
import { Grid } from '@material-ui/core';
import Image from 'next/image';

import RichContent from '../richContent';
import Spacer from '../spacer';

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

export default function IconWithText ({ icon, text, gridXs = 'auto', gridSm = 'auto', gridMd = 'auto', centerText = true, smallText = false, iconSize = false, }) {
    const size = iconSize || '120px';

    return (
        <Grid item xs={gridXs} md={gridMd} sm={gridSm} style={{ margin: centerText ? 'auto' : 'inherit', textAlign: centerText ? 'center' : 'inherit', }}>
            <Image src={base + icon} height={size} width={size} />
            <Spacer spaceMobile={1} />
            <RichContent content={text} centerText={centerText} smallText={smallText} />
            <Spacer spaceMobile={3} />
        </Grid>
    );
}
