import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

import theme from '../../theme';
import Spacer from '../spacer';
import Headline from '../headline';
import IconWithRichText from '../icons/iconWithRichText';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function Faq (props) {
    const classes = useStyles(theme);

    return (
        <Container>
            <Grid container item xs={12} className={classes.root} id={(props.name && encodeURI(props.name)) }>
                {props.contents.map((c, i) => {
                    if (c.fields.type) {
                        return (
                            <React.Fragment key={`${c.fields.name}-${i}`}>
                                {i !== 0 && (<Spacer spaceMobile={5} />)}
                                <Headline {...c.fields} containerStyles={{ padding: 0, }} />
                                <Spacer spaceMobile={3} />
                            </React.Fragment>
                        );
                    } else {
                        return <IconWithRichText
                            key={c.sys.id + i}
                            text={c.fields.text}
                            icon={c.fields.icon}
                            gridXs={6}
                            gridMd={4}
                            centerText={false}
                            smallText={true}
                        />;
                    }
                })}
            </Grid>
        </Container>
    );
}
