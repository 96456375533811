import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';

import theme from '../theme';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(5),
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(12),
        },
        position: 'relative',
    },
    ulListStyle: {
        paddingInlineStart: '12px',
        listStyleType: 'square',
    },
    listItemStyle: {
        marginBottom: theme.spacing(1),
        fontSize: '2rem',
        lineHeight: '1rem',
    },
    block1: {
        position: 'relative',
        right: 0,
    },
    image1: {
        position: 'relative',
        top: '-90px',
        [theme.breakpoints.up('sm')]: {
            right: '-200px',
        },
        [theme.breakpoints.up('md')]: {
            right: '-150px',
        },
        [theme.breakpoints.up('lg')]: {
            right: '-25px',
        },
    },
    textBox1: {
        color: 'white',
        maxHeight: '480px',
        backgroundColor: 'black',
        padding: theme.spacing(4),
        position: 'relative',
        top: '-120px',
        [theme.breakpoints.up('sm')]: {
            width: '380px',
            marginLeft: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '50px',
        },
    },
    textBox2: {
        position: 'relative',
        color: 'white',
        backgroundColor: 'black',
        padding: theme.spacing(4),
        zIndex: 100,

        [theme.breakpoints.up('sm')]: {
            width: '380px',
            float: 'right',
        },
        [theme.breakpoints.up('md')]: {
            float: 'inherit',
            right: '50px',
        },
    },
    image2: {
        position: 'relative',
        top: '-20px',
        right: '-10px',
        [theme.breakpoints.up('md')]: {
            top: '-40px',
            right: '-100px',
        },
        [theme.breakpoints.up('lg')]: {
            top: '-220px',
            right: '-300px',
        },
    },
    decoImage: {
        position: 'absolute',
        bottom: '0px',
        zIndex: -1,
        maxWidth: '1500px',
    },
}));

const base = 'https://res.cloudinary.com/thecodecosmetics/image/upload/f_auto,q_auto,c_lfill/';

export default function TwoImagesWithBackground (props) {
    const classes = useStyles(theme);
    const imageUrl = base + `${props.backgroundImage}`;
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Container className={classes.content}>
            <div
                className={classes.root}
                style={{ backgroundImage: `url(${imageUrl})`, zIndex: -2, }} id={(props.name && encodeURI(props.name)) } >
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.block1} >
                        <img
                            alt={'box'}
                            width={300}
                            className={classes.image1}
                            src={base + props.imageLeft}
                        />
                        <div className={classes.textBox1}>
                            <Typography variant="subtitle1" style={{ fontSize: '3.5rem', }} >
                                {props.headlineLeft}
                            </Typography>
                            <div>
                                <ul className={classes.ulListStyle}>
                                    {props.bulletPointsLeft.map((p, i) => (
                                        <li key={`${p[0]}-${i}`} className={classes.listItemStyle} >
                                            <span style={{ fontSize: '1rem', }} >{p}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid container item xs={12} md={6}>
                        <Grid item xs={12} md={7} >
                            <div className={classes.textBox2}>
                                <Typography variant="subtitle1" style={{ fontSize: '3.5rem', }} >
                                    {props.headlineRight}
                                </Typography>
                                <div>
                                    <ul className={classes.ulListStyle}>
                                        {props.bulletPointsRight.map((p, i) => (
                                            <li key={`${p[0]}-${i}`} className={classes.listItemStyle} >
                                                <span style={{ fontSize: '1rem', }} >{p}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <img
                                alt={'box'}
                                width={300}
                                className={classes.image2}
                                src={base + props.imageRight}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {props.decoImageLeft && isDesktop && (
                    <img
                        className={classes.decoImage}
                        src={base + props.decoImageLeft}
                    />
                )}
            </div>
        </Container>
    );
}
